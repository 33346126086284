export class ObfuscatedLinks {

  static process() {
    for (let link of $('a')) {
      const href = $(link).attr('href');
      const newHref = href.replace(/\{\-+\}/gi, '')
      if (newHref !== href) {
        $(link).attr('href', newHref);
      }
    }
  }

}