// Components
import { ActiveNav } from "./components/active_nav";
import { FormToucher } from "./components/form_toucher";
import { ObfuscatedLinks } from "./components/obfuscated_links";

$(document).ready(ObfuscatedLinks.process);

$(document).ready(ActiveNav.process);

$(document).ready(new FormToucher());
