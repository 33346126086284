export class ActiveNav {

  constructor() {}

  static process() {
    $(`a[href="${window.location.pathname}"]`).addClass('active');
    $(`a[href="${window.location.pathname}/"]`).addClass('active');
    $(`a[href="${window.location.pathname}/index.html"]`).addClass('active');
  }

}