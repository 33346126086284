export class FormToucher {

  constructor() {
    this.bindEvents();
  }

  bindEvents() {
    $('input, select').on('blur', this.touchField);
    $('input').on('input', this.touchField);
  }

  touchField(event) {
    $(this).addClass('touched');
  }

}